@import "./variables.scss";
@import "./navigation.scss";
@import "./home.scss";
@import "./footer.scss";
@import "./machinepage.scss";
@import "./pages.scss";
@import "./support.scss";
@import "./cards.scss";
@import "./buttons.scss";
@import "./gallery.scss";
@import "./forms.scss";
@import "./applications.scss";
@import "./options.scss";
@import "./alert.scss";
@import "./serial.scss";
@import "./demo-images.scss";
@import "./partsPage.scss";
@import "./blog-post-page.scss";
@import "./blog.scss";

* {
    margin: 0rem;
    padding: 0rem;
    box-sizing: border-box;
}
body {
    display: grid;
    min-height: 100vh;
    grid-template-rows: auto 1fr auto;
    font-family: sans-serif;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "obvia-expanded", sans-serif;
    font-weight: 800;
    font-style: normal;
}
p {
    line-height: 1.5rem;
}
ul {
    list-style-type: none;
}
a {
    text-decoration: none;
}
.page-wrapper {
    display: grid;
    padding-top: 3.5rem;
}
.padded-section {
    padding: $mobile-box-padding;
    iframe {
        width: 100%;
        min-height: 400px;
    }
}

main {
    width: 100%;
    margin: auto;
}

@include responsive(small) {
    main {
        width: 100%;
    }
}
@include responsive(medium) {
    main {
    }
    .padded-section {
        padding: $notepad-box-padding;
    }
    .footer {
        display: flex;
        margin: auto;
        flex-direction: column;
        .footer-columns {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;
            padding: $notepad-box-padding;
            ul {
                display: grid;
                width: 100%;
            }
            align-items: flex-start;
        }
        .footer-image-wrapper {
            img {
                width: 300px;
                height: auto;
            }
        }
    }
}
@include responsive(large) {
    main {
        display: grid;
        margin: auto;
        max-width: 1920px;
    }
    .footer {
        width: 1920px;
    }
    .padded-section {
        padding: $desktop-box-padding;
    }

}