@import "./variables.scss";

.page-two-columns {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 1rem;
    row-gap: 1rem;
    div {
        display: flex;
        flex-direction: column;
        width: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: auto;
            margin-bottom: 1rem;
        }
    }
}
.style {
    h2 {
        background-color: rgb(204, 210, 198);
        text-align: center;
        padding: .75rem 0rem;
        margin-bottom: 1rem;
    }
}
p {
    margin-bottom: .5em;
}
.page {
    &.padded {
        padding: $mobile-padding;
    }
    .page-heading {
        display: flex;
        justify-content: space-between;
        padding: $mobile-padding;
        background: $tertiary-color-lighter;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        &.center {
            justify-content: center;
            text-align: center;
        }
    }
}

.section-wrapper {
    background: $olive-green;
    padding-bottom: 2rem;
    .section-content-wrapper {
        margin: auto;
        max-width: 1200px;
        h1 {
            text-align: center;
            padding: 3rem 0rem 0rem 0rem;
        }
    }
}

@include responsive(small) {

}

@include responsive(medium) {
    #google-map {
        width: 100%;
        height: 570px;
    }
    .page-two-columns {
        display: grid;
        column-gap: 4rem;
        grid-template-columns: 1fr 1fr;
        ul {
            display: grid;
            align-content: flex-start;
        }
    }
    .page {
        &.padded {
            padding: $notepad-box-padding;
        }
        .page-heading {
            padding: $notepad-padding;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

}

@include responsive(large) {
    .page-two-columns {
        grid-template-columns: 1fr 1fr;
    }
    .page {
        &.padded {
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding: $desktop-box-padding;
        }
        .page-heading {
            padding: $desktop-padding;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
    }

}