@import "./variables.scss";

.support {
    display: grid;
}
.support-heading {
    display: flex;
    justify-content: space-between;
    padding: $mobile-padding;
}
.two-button-head {
    display: grid;
    row-gap: 1rem;
    a {
        display: flex;
        justify-content: center;
        justify-items: flex-end;
        font-size: .75rem;
        text-align: center;
        background: $primary-color;
        padding: .5rem 1rem;
        color: white;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
            background: $secondary-color;
        
        }
    }
}

@include responsive(small) {


}

@include responsive(medium) {
    .two-button-head {
        display: flex;
        column-gap: 1rem;
    }
    .support-heading {
        padding: $notepad-padding;
    }
}
@include responsive(large) {
    .support-heading {
        padding: $desktop-padding;
    }
}