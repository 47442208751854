@import "./variables.scss";
@import url("https://use.typekit.net/ckl8mry.css");
.machine-page {

    display: flex;
    flex-direction: column;
    margin-top: 3.5rem;
    padding: 0rem .5rem;
    .request-demo-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(226, 178, 89);
        text-decoration: none;
        color: white;
        padding: .5rem 1.5rem;
        font-size: .85rem;
    }
    .main-section-wrapper {
        display: flex;
        flex-direction: column;
    }
    .image-thumb-changer-wrapper {
        display: grid;
        width: 100%;
    }
    .thumbs-image-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1rem;
        width: 100%;
        row-gap: 1rem;
        .thumbnails-wrapper {
            display: flex;
            justify-content: space-evenly;
            row-gap: .25rem;
            margin-bottom: 1rem;
            .thumb {
                width: 50px;
                height: 50px;
                cursor: pointer;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: .25rem;
                }
            }
        }
        .image-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            img {
                height: 275px;
                width: auto;
                object-fit: contain;
            }
        }
    }
    .details-section-wrapper {
        display: flex;
        flex-direction: column;
        .heading-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            .title-wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding-bottom: .5rem;
                align-items: center;
                .title {
                    font-weight: bold;
                    font-size: 1.5rem;
                }
                .title-details {
                    display: grid;
                }
                .request-demo-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    width: max-content;
                    align-items: center;
                    background: $secondary-color;
                    border: none;
                    text-align: center;
                    cursor: pointer;
                    height: 2.5rem;
                    padding: 0rem 1rem;
                    color: white;
                    font-weight: bold;
                    transition: ease-in-out .2s;
                    &:hover {
                        background: $hover-color;
                    }
                }
            }
    
        }
        .description-wrapper {
            p {
                line-height: 1.5rem;
                font-size: .9rem;
            }
        
        }
    }
    .spec-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: max-content;
    margin: 0rem 0rem .5rem 0rem; 
    align-items: flex-start;
    column-gap: .125rem;
    row-gap: .125rem;
    text-align: left;
    width: 100%;
    .detail {
        display: flex;
        background: transparent;
        width: 100%;
        height: fit-content;
        font-size: .85rem;
        padding: .25rem 0rem;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .25);
        width: 100%;
        span {
            text-align-last: left;
            &.label {
                color: black;
                font-weight: bold;
                margin-right: .25rem;
                font-family: "obvia-expanded", sans-serif;
                font-weight: 800;
                font-style: normal;
            }
            &.data {
                color: #393939;
                font-style: italic;
            }
        }
    }
}
    .deck-selector-wrapper {
        display: grid;
        column-gap: 1rem;
        align-items: flex-start;
        margin: 1rem 0rem;
        h3 {
            font-size: .9rem;
        }
        
        h4 {
            font-size: .8rem;
            font-weight: bold;
        }
        img {
            width: 100%;
            height: max-content;
            object-fit: contain;
        }
        .decks-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid black;
                background-color: transparent;
                height: 2rem;
                width: 85px;
                font-weight: bold;
                &.selected {
                    background: black;
                    color: white;
                }
                &:hover {
                    border: 1px solid green;

                }
             }
            h3 {
                width: 100%;
                text-align: center;
                padding-bottom: .5rem;
            }
            .scrub-systems-wrapper {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                row-gap: 1rem;
                 .scrub-system {
                     display: grid;
                    text-align: center;
                    width: 75px;
                    transition: all .125s ease-in-out;
                    cursor: pointer;
                    &:hover {
                    transform: scale(1.1);
                    }
                }
             }
             .scrubdeck-sizes-wrapper {
                display: grid;
                width: 100%;
                .deck-sizes {
                    display: flex;
                    justify-content: space-evenly;
                }
            }
        }

    }
    .manuals-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start;
        align-content: flex-start;
        margin-top: .5rem;
        padding: 1rem 0rem;
        row-gap: 1rem;
        margin-bottom: 3rem;
        .manual {
            display: grid;
            align-items: flex-start;
            justify-content: center;
            justify-items: center;
            align-content: flex-start;
            cursor: pointer;
            height: max-content;
            padding: .5rem;
            width: 90px;
            height: 90px;
            fill: rgb(66, 66, 66);
            &:hover {
                color: rgb(0, 54, 0);
                .manual-image-wrapper {
                    svg {
                        fill: rgb(0, 54, 0);
                    }
                }
            }
            h4 {
                font-weight: normal;
                font-size: .9rem;
                text-align: center;
            }
            .manual-image-wrapper {
                display: grid;
                svg {
                    height: 40px;
                }
            }
        }
    }
    .machine-demo-wrapper {
        
    }
}
.machine-demo-request {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 0rem;
    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}

@include responsive(small) {
    .machine-page {
        .thumbs-image-wrapper {
            .thumbnails-wrapper {
                .thumb {
                    width: 75px;
                    height: 75px;
                    transition: all .125s ease-in-out;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
            .image-wrapper {
                img {
                    height: 300px;
                }
            }
        }
        .deck-selector-wrapper {
            display: grid;
            column-gap: 1rem;
            align-items: flex-start;
            margin: 1rem 0rem;
            h3 {
                font-size: .9rem;
            }
            
            h4 {
                font-size: .8rem;
                font-weight: bold;
            }
            img {
                width: 100%;
                height: max-content;
                object-fit: contain;
            }
            .decks-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                .scrub-systems-wrapper {
                     .scrub-system {
                         display: flex;
                         width: 125px;
                     }
                 }
            }
        }
    }
}

@include responsive(medium) {
    .machine-page {
        .main-section-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: $notepad-padding;
        }
        .thumbs-image-wrapper {
            .thumbnails-wrapper {
                .thumb {
                    width: 100px;
                    height: 100px;
                }
            }
            .image-wrapper {
                img {
                    height: 300px;
                }
            }
        }
        .deck-selector-wrapper {
            display: grid;
            column-gap: 1rem;
            align-items: flex-start;
            margin: 1rem 0rem;
            h3 {
                font-size: .9rem;
            }
            
            h4 {
                font-size: .8rem;
                font-weight: bold;
            }
            img {
                width: 100%;
                height: max-content;
                object-fit: contain;
            }
            .decks-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                .scrub-systems-wrapper {
                    display: flex;
                    justify-content: space-evenly;
                     .scrub-system {
                        display: grid;
                         width: 125px;
                     }
                 }
            }
        }
    }
}

@include responsive(large) {
    .machine-page {
        .main-section-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: $desktop-padding;
        }
    }
}
