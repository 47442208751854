@import "./variables.scss";

.demo-images-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    row-gap: 1rem;
    padding: 2rem 0rem 2rem 0rem;
    h1 {
        font-size: 3rem
    }
    .large-square {
        display: grid;
        align-items: center;
        align-content: center;
        color: white;
        width: 90vw;
        height: 90vw;
        background: $tertiary-color-light;
        padding: 1rem;
        row-gap: 1rem;
        h2 {
            font-size: 3rem;
        }
        h4 {
            font-weight: normal;
            font-size: 1.75rem;
        }
        a {
            text-decoration: underline;
            font-weight: bold;
            color: white;
            font-size: 1.5rem;
            text-transform: uppercase;
        }
    }
    .four-swatches {
        display: grid;
        row-gap: 1rem;
        justify-content: center;
    }
    .demo-image-wrapper {
        display: flex;
        border: 1px solid grey;
        width: 90vw;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        
        }
    }
    h1 {
        flex-basis: 100%;
        text-align: center;
        padding: 1rem 0rem;
    }
    .demo-image-wrapper {
        display: flex;
        border: 1px solid grey;
        width: 90vw;
        height: 90vw;
    }
}

@include responsive(small) {
    .demo-images-wrapper {
        display: grid;
        margin: auto;
        width: 768px;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
        h1 {
            grid-column: 1 / span 2;
        }
        .large-square {
            display: grid;
            justify-self: center;
            width: calc(384px);
            height: calc(384px);
        }
        .four-swatches {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            row-gap: 1rem;
            column-gap: 1rem;
        }
        .demo-image-wrapper {
            width: calc(192px - .5rem);
            height: calc(192px - .5rem);
        }
    }
}

@include responsive(medium) {
    .demo-images-wrapper {
        width: 1200px;
        .large-square {
            width: calc(600px);
            height: calc(600px);
        }
        .demo-image-wrapper {
            width: calc(300px - .5rem);
            height: calc(300px - .5rem);
        }
    }
}

@include responsive(large) {
    .demo-images-wrapper {
        width: 1800px;
        .large-square {
            width: calc(900px);
            height: calc(900px);
        }
        .demo-image-wrapper {
            width: calc(450px - .5rem);
            height: calc(450px - .5rem);
        }
    }
}
