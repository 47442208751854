@import "./variables.scss";

.cards-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0rem;
    row-gap: 2rem;
}
.support-card {
    display: grid;
    border: 2px solid $primary-color;
    width: 100%;
    margin-top: 1rem;
    .support-card-heading {
        display: flex;
        justify-content: center;
        padding: .75rem;
        background: $primary-color;
        color: white;
        h3 {
            font-weight: normal;
        }
    }
    .support-card-image {
        display: grid;
        justify-content: center;
        justify-items: center;
        width: 100%;
        img {
            display: flex;
            width: 100%;
            object-fit: contain;
            height: auto;
        }
    }
    .manuals-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        column-gap: 1rem;
        padding: 1rem;
        .manual {
            display: grid;
            justify-content: center;
            justify-items: center;
            overflow: hidden;
            width: 100px;
            .manual-image-wrapper {
                display: grid;
                align-items: flex-start;
                align-content: flex-start;
                svg {
                    width: 40px;
                    height: auto;
                    object-fit: contain;
                    cursor: pointer;
                    &:hover {
                        fill: red;
                    }
                }
            }
            h4 {
                display: flex;
                font-size: .75rem;
                text-align: center;
                width: 100%;
            }
        }

    }


}

.machine-card {
    display: grid;
    border: 2px solid $primary-color;
    width: 100%;
    .machine-image-container {
        display: grid;
        justify-content: center;
        justify-items: center;
        width: 100%;
        padding: 1rem;
        img {
            display: flex;
            width: 75%;
            object-fit: contain;
            height: auto;
        }
    }
    .card-content {
        display: grid;
        h4 {
            text-align: center;
            background: rgb(96, 107, 85);
            padding: 1rem 1rem;
            font-size: 1.5rem;
        }
        align-items: flex-start;
        ul {
            padding: 1rem .5rem;
            li {
                border-bottom: 1px solid black;;
                padding: .5rem 0rem;
                font-size: .8rem;
                &:last-child {
                    border-bottom: none;
                }
            }
            .spec-label {
                font-weight: bold;
                margin-right: .25rem;
            }
        }

        a {
            display: flex;
            justify-self: center;
            margin-bottom: 1rem;
            text-decoration: none;
            color: rgb(6, 6, 6);
            border: 1px solid $white-color;
            padding: 1rem;
            background: #333;
            padding: 1rem 3rem;
            width: max-content;
            color: white;
            transition: all .125s ease-in-out;
            &:hover {
                background: $hover-color;
                color: $white-color;
            }
        }
    }
}

.cta-cards {
    display: grid;
    row-gap: 2rem;
    justify-content: center;
    .cta-card {
        display: grid;
        border: 1px solid $primary-color;
        width: 100%;
        width: 300px;
        grid-template-rows: 200px 1fr;
        h3 {
            font-weight: normal;
        }
        .cta-card-image {
            display: grid;
            justify-content: center;
            width: 100%;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
        .card-content {
            display: grid;
            grid-template-rows: auto auto 1fr;
            background: $primary-color;
            color: white;
            text-align: center;
            padding: 1rem;
            a {
                align-self: flex-end;
                background: transparent;
                border: 2px solid white;
                color: white;
                padding: .5rem 1rem;
                height: max-content;
                margin-top: 2rem;
            &:hover {
                background: $hover-color;
            }
            }
        }
    }
}


@include responsive(small) {
    .cards-wrapper {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        column-gap: 4rem;
        flex-wrap: wrap;
    }
    .machine-card {
        width: 200px;
    }
    .cta-cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        .cta-card {
            display: grid;
            grid-template-rows: 200px auto;
            .cta-card-image {
                display: grid;
                justify-items: center;
                img {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }

        }
    }
    .support-card {
        margin-top: 1rem;
        width: 300px;
        .support-card-heading {
            display: flex;
            justify-content: center;
            padding: .75rem;
            background: $primary-color;
            color: white;
            h3 {
                font-weight: normal;
            }
        }
        .support-card-image {
            display: grid;
            justify-content: center;
            justify-items: center;
            width: 100%;
            padding: .5rem;
            img {
                display: flex;
                width: 70%;
                object-fit: contain;
                height: auto;
            }
        }
        .manuals-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
            column-gap: 1rem;
            padding: .5rem;
            row-gap: .5rem;
            border-top: 1px solid rgba($color: #000000, $alpha: .25);
            .manual {
                display: grid;
                justify-content: center;
                justify-items: center;
                overflow: hidden;
                .manual-image-wrapper {
                    display: grid;
                    align-items: flex-start;
                    align-content: flex-start;
                    svg {
                        width: 50px;
                        height: auto;
                        object-fit: contain;
                        cursor: pointer;
                        &:hover {
                            fill: red;
                        }
                    }
                }
                h4 {
                    display: flex;
                    font-size: .75rem;
                    text-align: center;
                    width: 100%;
                }
            }
    
        }
    
    
    }
}

@include responsive(medium) {
    .cards-wrapper {
        display: flex;
        flex-direction: row;
        row-gap: 2rem;
    }
    .machine-card {
        width: 300px;
    }
}

@include responsive(large) {
    .machine-card {
        width: 300px;
    }
}