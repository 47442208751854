@import "./variables.scss";
.blog-list-page {
    padding: $mobile-padding;
    padding-bottom: 4rem;
    .blog-page-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 1rem;
        color: $primary-color;
        padding-top: 1rem;
    }
    a {
        display: flex;
        align-items: center;
        color: $primary-color;
        font-weight: bold;
        text-decoration: none;
        transition: all .3s ease;
        &:hover {
            color: $hover-color;
        }
    }
}
.blog-posts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    row-gap: 1rem;

}
.blog-post-list-item {
    display: grid;
    grid-template-rows: 250px auto 1fr 1fr;
    align-content: flex-start;
    width: 250px;
    height: 500px;
    border: 1px solid rgba($color: $primary-color, $alpha: .5);
    div {
        display: grid;
        height: 100%;
    }
    .blog-post-list-item-image {
        display: grid;
        width: 100%;
        overflow: hidden;
        img {
            display: flex;
            width: 100%;
            height: max-content;
            object-fit: contain;
        }
    }
    .blog-post-tags {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        row-gap: .5rem;
        column-gap: .5rem;
        padding: .5rem;
        .tag {
            width: max-content;
            height: max-content;
            border: 1px solid $primary-color;
            padding: .25rem .5rem;
            border-radius: 1rem;
            font-size: .5rem;
            color: $primary-color;
        }
    }
    .blog-post-list-item-content {
        display: grid;
        font-size: 1rem;
        width: 100%;
        padding: 0rem .5rem;
        .blog-post-list-item-date {
            display: flex;
            font-style: italic;
            font-weight: bold;
            font-size: .7rem;
            justify-content: space-between;
            padding-bottom: .5rem;
            color: $primary-color;
            overflow: hidden;
        }
        .blog-post-list-item-title {
            display: grid;
            width: 100%;
            font-size: 1.25rem;
            font-weight: bold;
            color: black;
            padding-bottom: .25rem;
        }

        .blog-post-list-item-description {
            min-height: 60px;
            max-height: 60px;
            font-size: .85rem;
            padding-bottom: 1rem;
            color: #999;
            font-weight: 500;
            line-height: 1.2rem;
            overflow: hidden;
        }
        .blog-post-call-to-action {
            display: flex;
            align-items: center;
            font-size: 1rem;
            padding: 0rem .5rem;
            svg {
                font-size: 1.5rem;
                margin-left: .5rem;
            }
        }
    }
    .blog-post-call-to-action {
        display: grid;
        height: 100%;
        align-content: flex-end;
        padding-left: .5rem;
        padding-right: .5rem;
        padding-bottom: .5rem;
    }
}




@include responsive(small) {
    .blog-posts-wrapper {
        display: flex;
        justify-content: space-evenly;
    }
    .blog-post-list-item {
        display: grid;
        .blog-post-list-item-title {
            overflow: hidden;
        }
    }
}

@include responsive(medium) {
    .blog-list-page {
        padding: $notepad-padding;
    }
    .blog-posts-wrapper {
        justify-content: space-between;

    }
    .blog-post-list-item {
        display: grid;

        grid-template-columns: 1fr;
        grid-template-rows: 300px auto 1fr 1fr;
        width: 300px;
        height: 550px;
        .blog-post-list-item-content {
            display: grid;
            font-size: 1rem;
            padding: 0rem .5rem;
            width: 100%;
            .blog-post-list-item-title {
            }
            .blog-post-list-item-description {

            }

        }
        .blog-post-list-item-image {
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            width: 100%;
            overflow: hidden;
            height: 300px;
            img {
                display: flex;
                width: 100%;
                object-fit: contain;
            }
        }
        .blog-post-call-to-action {

        }
    }
}

@include responsive(large) {
    .blog-list-page {
        padding: $desktop-padding;
        padding-bottom: 4rem;
    }
    .blog-posts-wrapper {
        width: 100%;
    }
    .blog-post-list-item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 350px auto 1fr 1fr;
        width: 350px;
        height: 550px;
        .blog-post-list-item-title {
        }

        .blog-post-list-item-description {

        }
        .blog-post-list-item-image {
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            width: 100%;
            overflow: hidden;
            height: 350px;
            img {
                display: flex;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .blog-post-call-to-action {

    }
}