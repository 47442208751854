@import "./variables.scss";

.desktop-navigation {
    display: none;
}
.mobile-navigation-wrapper {
    display: grid;
    min-height: 100vh;
    position: fixed;
    z-index: 999;
    background: transparent;
}
.mobile-navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    height: 3.5rem;
    background: $primary-color;
    padding: $mobile-padding;
    z-index: 4;
    .logo-wrapper {
        img {
            object-fit: contain;
            height: 2.5rem;
            width: 100%;
        }
    }
    svg {
        color: white;
        font-size: 1.65rem;
        cursor: pointer;
        &:hover {
            color: $secondary-color;
        }
    }
}
.mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 3rem;
    background: white;
    width: 100%;
    height: max-content;
    z-index: 99999;
    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding: $mobile-box-padding;
        li {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            cursor: pointer;
            padding: .75rem .5rem;
            border-radius: .125rem;
            border-bottom: 1px solid rgba($color: #000000, $alpha: .225);
            a {
                text-decoration: none;
                color: $primary-color;
                font-weight: 600;
                width: 100%;
            }
            svg {
                color: $primary-color;
            }
        }
    }
}
.mobile-menu-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba($color: $primary-color, $alpha: .25);
    transform: translateX(-100%);
    transition: ease-in-out 0.3s;
    &.open {
        transform: translateX(0%);
    }
}


@include responsive(medium) {
    .desktop-navigation {
        display: grid;
        margin: auto;
        justify-self: center;
        position: fixed;
        grid-template-columns: 150px 6fr auto;
        align-items: center;
        height: 3.5rem;
        background: $primary-color;
        padding: $desktop-padding;
        z-index: 999;
        top: 0;
        width: 100%;
        .navigation-links {
            display: flex;
            justify-content: left;
            margin-left: 4rem;
            a {
                display: flex;
                align-items: center;
                color: $light-grey;
                text-decoration: none;
                margin: 0rem .5rem;
                &:hover {
                    color: $secondary-color;
                }
            }
        }
        .navigation-cta {
            justify-self: end;
            a {
                color: $light-grey;
                text-decoration: none;
                padding: 0.35rem 1rem;
                border: 1px solid $light-grey;
                border-radius: 5px;
                margin: 0rem 0rem 0rem 1rem;
                &:hover {
                    background: $light-grey;
                    color: $primary-color;
                }
            }
        }
        .navigation-logo {
            justify-self: flex-start;
            width: 190px;
            align-items: center;
            align-content: center;
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
    .mobile-navigation-wrapper {
        display: none;
    }
    .mobile-menu {
        display: none;
    }
    .mobile-navigation {
        display: none;
    }
}

@include responsive(large) {
    .desktop-navigation {
        max-width: 1920px;
    }
}