@import "./variables.scss";

.serial-results {
    .serial-two-columns {
        display: grid;
        row-gap: 1rem;
    }
    .column-container {
        display: grid;
        row-gap: .75rem;
    }
    .box-wrapper {
        display: grid;
    }
    .local-dealer {
        margin-top: 1rem;
    }
}

@include responsive(small) {
    .serial-results {
        display: grid;
        .serial-two-columns {
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            column-gap: 1rem;
        }
        .column-container {
            grid-template-columns: 1fr;
        }
    }
    .manuals-wrapper {
        display: flex;
        margin-top: .5rem;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-template-columns: 1fr;
        row-gap: 1rem;
        a {
            color: black;
            font-weight: normal;
        }
        svg {
            font-size: 1.5rem;
            color: orange;
        }
    }
    .manuals-box {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
    }
    .image-wrapper {
        display: flex;
        margin-right: .5rem;
    }
}

@include responsive(medium) {
    .serial-results {
        .serial-two-columns {
            grid-template-columns: 1fr 1fr;
            column-gap: 1rem;
        }
        .column-container {
            grid-template-columns: 1fr;
            &.manuals {
                grid-column: span 2;
            }
        }
    }
}