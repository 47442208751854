@import url("https://use.typekit.net/ckl8mry.css");
$primary-color: #424242;
$secondary-color: #f26522;
$light-grey: #cacaca;
$models-background-color: #e6e6e6;
$tertiary-color: #44542d;
$olive-green: rgb(96, 107, 85);;
$tertiary-color-light: #5a6b3d;
$tertiary-color-lighter: rgb(204, 210, 198);
$white-color: #ffffff;
$desktop-padding: 0rem 6rem;
$desktop-box-padding: 1rem 6rem;
$notepad-padding: 0rem 4rem;
$notepad-box-padding: 1rem 4rem;
$mobile-padding: 0rem .5rem;
$mobile-box-padding: .5rem .5rem;
$hover-color: #f87b41;
$breakpoints: (
  "small": 768px,
  "medium": 1200px,
  "large": 1920px,
);
@mixin responsive($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
      // Prints a media query based on the value
      @media (min-width: $value) {
        @content;
      }
    } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
  }