.cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem 1rem;
    background: $secondary-color;
    color: white;
    text-transform: uppercase;
    transition: all 0.3s ease;
    text-align: center;
    text-transform: none;
    border: none;
    border-radius: .25rem;
    cursor: pointer;
    &:hover {
        background: $hover-color;
    }
}