@import './variables.scss';

.demo-form {
  display: grid;
  padding: 1rem 0rem;
  &.contact {
    max-width: 600px;
  }
  ul {
    li {
      color: #444;
      font-size: .85rem;
    }
  }
  .form-two-column {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-three-column {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-section {
    padding: 1rem 0rem;
    .section-title {
      background-color: $tertiary-color-lighter;
      text-align: center;
      padding: .75rem 0rem;
      margin-bottom: 1rem;
    }
    .input-group {
      display: grid;
      padding: .5rem 0rem;
      display: flex;
      flex-direction: column;
      .error {
        color: red;
        font-weight: bold;
      }
      &.flex {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        align-content: center;
        input[type="checkbox"] {
          margin-right: .75rem;
        }
      }
    }

    label {
      margin-bottom: .25em;
      font-weight: bold;
      font-size: .8rem;
      .error {
        color: red;
        font-size: .7rem;
        margin-left: .5rem;
      }
    }
    input, select {
      padding: .5rem;
      border: 1px solid $tertiary-color-lighter;
      border-radius: 5px;
    }

  }
  textarea {
    padding: .5rem;
    border: 1px solid $tertiary-color-lighter;
    border-radius: 5px;
    width: 100%;
    height: 150px;
    resize: none;
  }
  button {
    padding: .5rem;
    border: 1px solid $tertiary-color-lighter;
    border-radius: 5px;
    background-color: $primary-color;
    color: white;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
  }
}

@include responsive(small) {
  .demo-form {
    display: flex;
    flex-direction: column;
    .form-two-column {
      display: grid;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
    .form-three-column {
      display: grid;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

}
@include responsive(large) {

}