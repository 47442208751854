@import "./variables.scss";

.models-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding: $mobile-box-padding;
    row-gap: 1rem;
    background: white;
    h2 {
        font-size: 1rem;
    }
    .model-card {
        display: grid;
        justify-items: center;
        width: 140px;
        align-content: center;
        align-items: center;
        height: auto;
        text-align: center;
        cursor: pointer;
        img {
            width: 100%;
            height: auto;
            margin-bottom: .5rem;
            transition: ease-in-out 0.2s;
            &:hover {
                transform: scale(1.1);
            }
        }
        h2 {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: .5rem;
            width: 100%;
        }
        p {
            text-align: center;
        }
    }
}
.two-columns {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    iframe {
        height: 400px;
    }
    &.centered {
        justify-content: space-between;
        justify-items: center;
        row-gap: 2rem;
    }
    .content-column {
        display: flex;
        flex-direction: column;
        padding: $mobile-box-padding;
        background: $olive-green;
        color: white;
        padding: 2rem 1rem;
        width: 100%;
        h1 {
            margin-bottom: 1rem;
            font-size: 2.25rem;
        }
        a {
            display: flex;
            margin-top: 1rem;
            text-align: center;
            align-self: center;
            padding: .5rem 1rem;
            width: max-content;
            border-radius: .25rem;
            color: white;
            background: $secondary-color;
            &:hover {
                background: $tertiary-color;
            }
        }
        p {
            margin-bottom: .5rem;
            font-size: 14pt;
            line-height: 2rem;
            color: rgba($color: #ededed, $alpha: 1.0);
        }
    }
    .two-column-image-wrapper {
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: flex-start;
        box-sizing: content-box;
        height: inherit;
        img {
            object-fit: fill;
            scale: 1.5;
            width: 100%;
            height: fit-content;
        }
    }
}
.hero-section {
    width: 100%;
    position: relative;
    .hero-image-wrapper {
        display: flex;
        align-items: center;
        height: 600px;
        overflow: hidden;
        justify-content: center;
        background: black;
        .image-content-wrapper {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            justify-items: center;
            text-align: center;
            color: white;
            z-index: 1;
            width: 100%;
            a {
                display: flex;
                margin-top: 1rem;
                text-align: center;
                align-self: center;
                padding: 1rem 2rem;
                width: max-content;
                border-radius: .25rem;
                color: white;                
                background: $secondary-color;
                transition: ease-in-out 0.2s;
                &:hover {
                    background: $tertiary-color;
                }
            }
        }
        h1 {
            margin-bottom: 1rem;
        }
        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        h3 {
            font-size: 1.25rem;
            font-weight: normal;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 600px;
            opacity: .5;
        }
    }
}

@include responsive(small) {
    .models-container {
        grid-template-columns: 1fr 1fr 1fr;
        .model-card {
            width: 100%;
            width: 170px;
        }
    }
}

@include responsive(medium) {
    .models-container {
        display: flex;
        flex-wrap: wrap;
        padding: $notepad-box-padding;
        justify-content: space-around;
        justify-items: center;
        background: white;

    }
    .two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        overflow: hidden;
        background: $olive-green;
        iframe {
            height: 100%;
        }
        &.normal {
            background: transparent;
        }
        &.centered {
            justify-content: space-between;
            justify-items: center;
        }
        .content-column {
            display: flex;
            flex-direction: column;
            padding: 3rem 2rem;
            color: white;
            height: max-content;
            width: 100%;
            h1 {
                text-align: left;
                margin-bottom: 1rem;
                font-size: 2.25rem;
            }
            a {
                display: flex;
                margin-top: 1rem;
                text-align: center;
                align-self: center;
                padding: 1rem 2rem;
                width: max-content;
                border-radius: .25rem;
                color: white;
                background: $secondary-color;
                &:hover {
                    background: $tertiary-color;
                }
            }
            p {
                margin-bottom: .5rem;
                color: rgba($color: #ededed, $alpha: 1.0);
            }
        }
        .two-column-image-wrapper {
            display: flex;
            width: 100%;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

@include responsive(large) {
    .models-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .model-card {
            display: grid;
            width: 200px;
            padding: 1rem;
        }
    }
}